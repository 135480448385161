*,
*:after,
*:before {
    box-sizing: border-box;
}

.container,
.container > * {
    width: 100%;
    max-width: 1280px;
    margin: 0.25rem;
}

.container textarea {
    height: 30vh;
}

button {
    height: 2rem;
}
